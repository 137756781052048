//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FileSaver from 'file-saver';
import ImageReview from '@/components/tools/ImageReview';
import AdvancedInfo from './child/AdvancedInfo.vue';
import { getCodeStatusName } from '@/components/_util/util';

export default {
  components: {
    ImageReview,
    AdvancedInfo,
    Manual: () => import('./child/manual/Index'),
    ManualEdit: () => import('./child/manual/_id'),
    Import: () => import('./child/import/Index'),
    TreeSelect: () => import('@/components/tools/TreeSelect.vue')
  },
  props: {
    type: { type: String, required: true, default: '' },
    code: { type: Object, default: () => ({}) },
    versions: { type: Array, default: () => [] }
  },
  data() {
    return {
      title: '',
      currentTab: '1',
      isHiddenTitle: false,
      isOpenVersionModal: false,
      category: {
        id: null,
        name: null
      },
      thumbnail: { url: '', alt: '' }
    };
  },
  created() {
    this.initialData();
  },
  computed: {
    validateStatusTitle() {
      return this.title === '' ? 'error' : 'success';
    },
    isActiveImport() {
      return this.type !== 'edit-code';
    },
    codeStatus() {
      return getCodeStatusName(this.code);
    },
    colorCodeType() {
      const mapping = {
        voice_code: 'pink',
        spot_code: 'cyan',
        navi_code: 'blue',
        mix_code: 'purple'
      };
      return mapping[this.code.code_type];
    }
  },
  methods: {
    async initialData() {
      this.title = this.code?.title || '';
      this.category = { id: this.code?.category_id };
      this.thumbnail = this.code?.thumbnail;
    },
    onChangeTitle(e) {
      this.title = e.target.value;
    },
    onSelect(node) {
      this.category = {
        name: node?.name || null,
        id: node?.id || null
      };
    },
    onSetFirstTime(value) {
      this.isHiddenTitle = value;
    },
    onSetTitle(title) {
      this.title = title;
      this.isHiddenTitle = false;
    },
    onSetTab(tab) {
      this.currentTab = tab;
    },
    download() {
      const timestamp = new Date().getTime();
      FileSaver.saveAs(
        `${this.code.preview_qrcode}?time=${timestamp}`,
        `univoice_code${this.code.id}.bmp`
      );
    },
    changeThumbnail({ url, alt }) {
      this.thumbnail = { ...this.thumbnail, url, alt };
    }
  }
};

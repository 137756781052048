var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-collapse",
    [
      _c(
        "a-collapse-panel",
        { key: "1", attrs: { header: _vm.$t("Advanced Information") } },
        [
          _c(
            "a-card",
            [
              _c("div", { attrs: { slot: "title" }, slot: "title" }, [
                _c("strong", [_vm._v(_vm._s(_vm.$t("Attached photo")))]),
                _c(
                  "span",
                  {
                    staticStyle: {
                      "font-size": "0.8rem",
                      "font-weight": "500",
                      "white-space": "pre-wrap"
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "(If you set an image, it will be displayed as a thumbnail image of Reading Homepage)"
                          )
                        ) +
                        " "
                    )
                  ]
                )
              ]),
              _c("div", [_vm._v(_vm._s(_vm.$t("Attached photo")) + ":")]),
              _c("upload-photo", {
                attrs: { url: _vm.url, "folder-name-s3": _vm.folderNameS3 },
                on: {
                  upload: function(newURL) {
                    _vm.$emit(
                      "changeThumbnail",
                      Object.assign({}, _vm.thumbnail, { url: newURL })
                    )
                  },
                  delete: function() {
                    _vm.$emit("changeThumbnail", { url: "", alt: "" })
                  }
                }
              }),
              _c("br"),
              _c("div", [_vm._v(_vm._s(_vm.$t("Alt texts")) + ":")]),
              _c(
                "a-form",
                { staticStyle: { width: "100%" } },
                [
                  _c(
                    "a-form-item",
                    {
                      staticClass: "mb-none",
                      attrs: {
                        "validate-status": _vm.validateThumbnailALT,
                        help:
                          _vm.validateThumbnailALT === "error" &&
                          _vm.$t("Photo alt text is required")
                      }
                    },
                    [
                      _c("a-input", {
                        attrs: { disabled: !_vm.url, value: _vm.alt },
                        on: {
                          change: function(ref) {
                            var target = ref.target

                            _vm.$emit(
                              "changeThumbnail",
                              Object.assign({}, _vm.thumbnail, {
                                alt: target.value
                              })
                            )
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import UploadPhoto from '@/components/spec/UploadPhoto.vue';

export default {
  props: {
    thumbnail: { type: Object, default: () => ({ url: '', alt: '' }) }
  },

  components: { UploadPhoto },

  computed: {
    url() {
      return this.thumbnail?.url || '';
    },
    alt() {
      return this.thumbnail?.alt || '';
    },
    validateThumbnailALT() {
      return this.url && !this.alt ? 'error' : 'success';
    },
    folderNameS3() {
      return `${process.env.VUE_APP_S3_CODE_THUMBNAIL}/${this.$route.params.pageId}`;
    }
  }
};

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "code-compose" },
    [
      _vm.currentTab === "1"
        ? _c(
            "div",
            [
              Object.keys(_vm.code).length > 0
                ? _c(
                    "a-row",
                    {
                      staticClass: "mb-xs",
                      attrs: { type: "flex", align: "middle" }
                    },
                    [
                      _c("a-col", { attrs: { xs: 24, lg: 15 } }, [
                        _c("div", { staticClass: "content-start mb-sm" }, [
                          _c("div", { staticClass: "mr-md" }, [
                            _c("span", { staticClass: "mr-xs" }, [
                              _vm._v(_vm._s(_vm.$t("ID")) + ":")
                            ]),
                            _c("span", { staticClass: "font-bold" }, [
                              _vm._v(
                                _vm._s(_vm._f("formatId")(_vm.code.id, "CO"))
                              )
                            ])
                          ]),
                          _c("div", [
                            _c("span", { staticClass: "mr-xs" }, [
                              _vm._v(_vm._s(_vm.$t("Status")) + ":")
                            ]),
                            _c("span", { staticClass: "font-bold" }, [
                              _vm._v(_vm._s(_vm.codeStatus))
                            ])
                          ])
                        ]),
                        _c(
                          "div",
                          { staticClass: "content-start mb-sm" },
                          [
                            _c("span", { staticClass: "mr-xs" }, [
                              _vm._v(_vm._s(_vm.$t("Code name")) + ": ")
                            ]),
                            _c("a-input", {
                              staticStyle: { width: "250px" },
                              attrs: {
                                value: _vm.title,
                                placeholder: _vm.$t("Title code demo text")
                              },
                              on: { change: _vm.onChangeTitle }
                            })
                          ],
                          1
                        ),
                        _c("div", { staticClass: "content-start mb-sm" }, [
                          _c(
                            "div",
                            { staticClass: "mr-md content-start" },
                            [
                              _c("span", { staticClass: "mr-xs" }, [
                                _vm._v(_vm._s(_vm.$t("Code type")) + ":")
                              ]),
                              _c(
                                "a-tag",
                                { attrs: { color: _vm.colorCodeType } },
                                [_vm._v(_vm._s(_vm.$t(_vm.code.code_type)))]
                              )
                            ],
                            1
                          ),
                          _c("div", [
                            _c("span", { staticClass: "mr-xs" }, [
                              _vm._v(_vm._s(_vm.$t("Create date")) + ":")
                            ]),
                            _c("span", [
                              _vm._v(_vm._s(_vm._f("dayjs")(_vm.code.created)))
                            ])
                          ])
                        ])
                      ]),
                      _vm.code.preview_qrcode
                        ? _c(
                            "a-col",
                            { attrs: { xs: 24, lg: 9 } },
                            [
                              _c(
                                "a-row",
                                { attrs: { type: "flex", align: "middle" } },
                                [
                                  _c(
                                    "a-col",
                                    {
                                      attrs: {
                                        xs: 10,
                                        lg: 6,
                                        xl: 6,
                                        type: "flex",
                                        align: "middle"
                                      }
                                    },
                                    [
                                      _c("image-review", {
                                        attrs: {
                                          data: _vm.code.preview_qrcode,
                                          size: 100
                                        }
                                      }),
                                      _c(
                                        "a-button",
                                        {
                                          staticStyle: { padding: "0" },
                                          attrs: { type: "link" },
                                          on: {
                                            click: function($event) {
                                              return _vm.download(
                                                _vm.code.preview_qrcode,
                                                _vm.code.id
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("Download")) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-col",
                                    { attrs: { xs: 14, lg: 18, xl: 18 } },
                                    [
                                      _vm.versions.length > 0
                                        ? _c(
                                            "a-button",
                                            {
                                              staticStyle: { padding: "0" },
                                              attrs: { type: "link" },
                                              on: {
                                                click: function() {
                                                  return (_vm.isOpenVersionModal = true)
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "*View past versions"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _c(
                    "a-row",
                    {
                      staticClass: "mb-xs",
                      attrs: { type: "flex", justify: "start", align: "middle" }
                    },
                    [
                      _c("span", { staticClass: "mr-xs" }, [
                        _vm._v(_vm._s(_vm.$t("Code name")) + ":")
                      ]),
                      _c("a-input", {
                        staticStyle: { width: "250px" },
                        attrs: {
                          value: _vm.title,
                          placeholder: _vm.$t("Title code demo text")
                        },
                        on: { change: _vm.onChangeTitle }
                      })
                    ],
                    1
                  )
            ],
            1
          )
        : _vm._e(),
      _c(
        "a-row",
        {
          staticClass: "mb-xs",
          attrs: { type: "flex", justify: "start", align: "middle" }
        },
        [
          _c("span", { staticClass: "mr-xs" }, [
            _vm._v(_vm._s(_vm.$t("Category List")) + ":")
          ]),
          _c("tree-select", {
            staticStyle: { width: "40%" },
            attrs: {
              "allow-create": "",
              category: _vm.category,
              pageId: +_vm.$route.params.pageId
            },
            on: { select: _vm.onSelect }
          })
        ],
        1
      ),
      _c("advanced-info", {
        attrs: { thumbnail: _vm.thumbnail },
        on: { changeThumbnail: _vm.changeThumbnail }
      }),
      _c("br"),
      _c(
        "a-tabs",
        {
          model: {
            value: _vm.currentTab,
            callback: function($$v) {
              _vm.currentTab = $$v
            },
            expression: "currentTab"
          }
        },
        [
          _c(
            "a-tab-pane",
            { key: "1" },
            [
              _c(
                "span",
                { attrs: { slot: "tab" }, slot: "tab" },
                [
                  _c("a-icon", { attrs: { type: "file-add" } }),
                  _c("span", [_vm._v(_vm._s(_vm.$t("Manual")))])
                ],
                1
              ),
              _vm.type === "compose-code"
                ? _c("manual", {
                    attrs: {
                      title: _vm.title,
                      firstTime: _vm.isHiddenTitle,
                      category: _vm.category,
                      thumbnail: _vm.thumbnail
                    },
                    on: {
                      setFirstTime: _vm.onSetFirstTime,
                      setTitle: _vm.onSetTitle,
                      setCurrentTab: _vm.onSetTab
                    }
                  })
                : _vm.type === "edit-code" && _vm.code
                ? _c("manual-edit", {
                    attrs: {
                      title: _vm.title,
                      code: _vm.code,
                      category: _vm.category,
                      thumbnail: _vm.thumbnail
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "2", attrs: { disabled: !_vm.isActiveImport } },
            [
              _c(
                "span",
                { attrs: { slot: "tab" }, slot: "tab" },
                [
                  _c("a-icon", { attrs: { type: "import" } }),
                  _c("span", [_vm._v(_vm._s(_vm.$t("Import File")))])
                ],
                1
              ),
              _c("import", {
                attrs: { title: _vm.title, category: _vm.category }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { footer: null },
          model: {
            value: _vm.isOpenVersionModal,
            callback: function($$v) {
              _vm.isOpenVersionModal = $$v
            },
            expression: "isOpenVersionModal"
          }
        },
        [
          _c("p", { staticClass: "title font-bold" }, [
            _vm._v(_vm._s(_vm.$t("Versions history")))
          ]),
          _c("a-list", {
            attrs: { "item-layout": "horizontal", "data-source": _vm.versions },
            scopedSlots: _vm._u([
              {
                key: "renderItem",
                fn: function(item) {
                  return _c(
                    "a-list-item",
                    {},
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { slot: "actions", type: "link" },
                          on: {
                            click: function($event) {
                              return _vm.download(
                                item.preview_qrcode,
                                item.code_id
                              )
                            }
                          },
                          slot: "actions"
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("Download")) + " ")]
                      ),
                      _c(
                        "a-list-item-meta",
                        {
                          attrs: { description: _vm._f("dayjs")(item.created) }
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(item.title))]
                          )
                        ]
                      )
                    ],
                    1
                  )
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }